import { Form, Modal } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { NodeValues } from '../../types';

export type InsertValues = {
  key: string;
  label: string;
  // InputElement: ComponentType<HTMLTextAreaElement | HTMLInputElement>;
  InputElement: any;
  rules?: Rule[];
};

export type Props = {
  title: string;
  visible: boolean;
  onOk: (newValues: NodeValues) => void;
  onCancel: () => void;
  values: InsertValues[];
};

const InsertModal: FunctionComponent<Props> = ({
  title,
  visible,
  onCancel,
  onOk,
  values,
}: Props) => {
  const [form] = Form.useForm();

  const validateInsert = useCallback(async () => {
    const isFormValid = await form.validateFields();
    if (isFormValid) {
      onOk(form.getFieldsValue() as NodeValues);
    }
  }, [form, onOk]);

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={validateInsert}
    >
      <Form onFinish={validateInsert} form={form} layout="vertical">
        {values.map(({ key, label, InputElement, rules }, i: number) => (
          <Form.Item key={key} name={key} label={label} rules={rules}>
            <InputElement autoFocus={i === 0} onPressEnter={validateInsert} />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default InsertModal;
