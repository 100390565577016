// import docTypesFlat from  "../../doc_classification.json"
// export docTypesFlat;

// export type DocType = {
//   title?: string;
//   value: string;
//   label?: string;
//   children: DocType[];
//   key?: string;
//   hasChild: (key: string) => boolean;
// };

// function hasChild(this: DocType, key: string): boolean {
//   return this.children.some(
//     (child) => key === child.key || child.hasChild(key)
//   );
// }

export const docTags = [
  'Copyrighted',
  'Foreign language',
  'Limited distribution',
  'Review',
  'Uninteresting',
];

export default 0;

// export const docTypes = docTypesFlat.documents_types.reduce(
//   (acc: any[], { id, type, path }: any) => {
//     const pathSplit = path.split('.');
//     const element = {
//       title: type,
//       label: type,
//       value: id,
//       hasChild,
//       children: [],
//     };

//     // console.log(element);

//     switch (pathSplit.length) {
//       case 1:
//         acc.push(element);
//         break;
//       case 2:
//         acc
//           .filter(({ value }) => value === parseInt(pathSplit[0]))[0]
//           .children.push(element);
//         break;
//       case 3:
//         acc
//           .filter(({ value }) => value === parseInt(pathSplit[0]))[0]
//           .children.filter(
//             ({ value }: any) => value === parseInt(pathSplit[1])
//           )[0]
//           .children.push(element);
//         break;
//       case 4:
//         acc
//           .filter(({ value }) => value === parseInt(pathSplit[0]))[0]
//           .children.filter(
//             ({ value }: any) => value === parseInt(pathSplit[1])
//           )[0]
//           .children.filter(
//             ({ value }: any) => value === parseInt(pathSplit[2])
//           )[0]
//           .children.push(element);
//         break;
//     }

//     return acc;
//   },
//   []
// );

// export const docTypes: DocType[] = [
//   {
//     title: "General",
//     label: "General",
//     value: "1",
//     hasChild,
//     children: [
//       {
//         title: "Something else",
//         label: "Something else",
//         value: "1.1",
//         hasChild,
//         children: [
//           {
//             title: "Is it really that nested ?",
//             label: "Is it really that nested ?",
//             hasChild,
//             children: [],
//             value: "1.1.1",
//           },
//           { title: "Naw",label: "Naw", hasChild, children: [], value: "1.1.2" },
//         ],
//       },
//       { title: "Another",label: "Another", hasChild, children: [], value: "1.2" },
//       {
//         title: "One",
//         label: "One",
//         value: "1.3",
//         hasChild,
//         children: [
//           { title: "Bites",label: "Bites", hasChild, children: [], value: "1.3.1" },
//           { title: "The",label: "The", hasChild, children: [], value: "1.3.2" },
//           { title: "Dust",label: "Dust", hasChild, children: [], value: "1.3.3" },
//         ],
//       },
//     ],
//   },
//   {
//     title: "Other",
//     label: "Other",
//     value: "2",
//     hasChild,
//     children: [{ title: "Almost",label: "Almost", hasChild, children: [], value: "2.1" }],
//   },
//   { title: "Empty",label: "Empty", value: "3", hasChild, children: [] },
// ];

// export const docRegions: DocType[] = [
//   {
//     title: 'Asia',
//     value: 'Asia',
//     key: '1',
//     hasChild,
//     children: [
//       { title: 'China', value: 'China', hasChild, key: '1.1', children: [] },
//     ],
//   },
//   {
//     title: 'Europe',
//     value: 'Europe',
//     key: '2',
//     hasChild,
//     children: [
//       {
//         title: 'Albania',
//         value: 'Albania',
//         hasChild,
//         key: '2.01',
//         children: [],
//       },
//       {
//         title: 'Andorra',
//         value: 'Andorra',
//         hasChild,
//         key: '2.02',
//         children: [],
//       },
//       {
//         title: 'Austria',
//         value: 'Austria',
//         hasChild,
//         key: '2.03',
//         children: [],
//       },
//       {
//         title: 'Belarus',
//         value: 'Belarus',
//         hasChild,
//         key: '2.04',
//         children: [],
//       },
//       {
//         title: 'Belgium',
//         value: 'Belgium',
//         hasChild,
//         key: '2.05',
//         children: [],
//       },
//       {
//         title: 'Bosnia and Herzegovina',
//         value: 'Bosnia and Herzegovina',
//         hasChild,
//         key: '2.06',
//         children: [],
//       },
//       {
//         title: 'Bulgaria',
//         value: 'Bulgaria',
//         hasChild,
//         key: '2.07',
//         children: [],
//       },
//       {
//         title: 'Croatia',
//         value: 'Croatia',
//         hasChild,
//         key: '2.08',
//         children: [],
//       },
//       {
//         title: 'Czech Republic',
//         value: 'Czech Republic',
//         hasChild,
//         key: '2.09',
//         children: [],
//       },
//       {
//         title: 'Denmark',
//         value: 'Denmark',
//         hasChild,
//         key: '2.10',
//         children: [],
//       },
//       {
//         title: 'Estonia',
//         value: 'Estonia',
//         hasChild,
//         key: '2.11',
//         children: [],
//       },
//       {
//         title: 'Finland',
//         value: 'Finland',
//         hasChild,
//         key: '2.12',
//         children: [],
//       },
//       { title: 'France', value: 'France', hasChild, key: '2.13', children: [] },
//       {
//         title: 'Germany',
//         value: 'Germany',
//         hasChild,
//         key: '2.14',
//         children: [],
//       },
//       { title: 'Greece', value: 'Greece', hasChild, key: '2.15', children: [] },
//       {
//         title: 'Holy see',
//         value: 'Holy see',
//         hasChild,
//         key: '2.16',
//         children: [],
//       },
//       {
//         title: 'Hungary',
//         value: 'Hungary',
//         hasChild,
//         key: '2.17',
//         children: [],
//       },
//       {
//         title: 'Iceland',
//         value: 'Iceland',
//         hasChild,
//         key: '2.18',
//         children: [],
//       },
//       {
//         title: 'Ireland',
//         value: 'Ireland',
//         hasChild,
//         key: '2.19',
//         children: [],
//       },
//       { title: 'Italy', value: 'Italy', hasChild, key: '2.20', children: [] },
//       { title: 'Latvia', value: 'Latvia', hasChild, key: '2.21', children: [] },
//       {
//         title: 'Lithuania',
//         value: 'Lithuania',
//         hasChild,
//         key: '2.22',
//         children: [],
//       },
//       {
//         title: 'Luxembourg',
//         value: 'Luxembourg',
//         hasChild,
//         key: '2.23',
//         children: [],
//       },
//       { title: 'Malta', value: 'Malta', hasChild, key: '2.24', children: [] },
//       {
//         title: 'Moldova',
//         value: 'Moldova',
//         hasChild,
//         key: '2.25',
//         children: [],
//       },
//       { title: 'Monaco', value: 'Monaco', hasChild, key: '2.26', children: [] },
//       {
//         title: 'Montenegro',
//         value: 'Montenegro',
//         hasChild,
//         key: '2.27',
//         children: [],
//       },
//       {
//         title: 'Netherlands',
//         value: 'Netherlands',
//         hasChild,
//         key: '2.28',
//         children: [],
//       },
//       {
//         title: 'North Macedonia',
//         value: 'North Macedonia',
//         hasChild,
//         key: '2.29',
//         children: [],
//       },
//       { title: 'Norway', value: 'Norway', hasChild, key: '2.30', children: [] },
//       { title: 'Poland', value: 'Poland', hasChild, key: '2.31', children: [] },
//       {
//         title: 'Portugal',
//         value: 'Portugal',
//         hasChild,
//         key: '2.32',
//         children: [],
//       },
//       {
//         title: 'Romania',
//         value: 'Romania',
//         hasChild,
//         key: '2.33',
//         children: [],
//       },
//       { title: 'Russia', value: 'Russia', hasChild, key: '2.34', children: [] },
//       {
//         title: 'San Marino',
//         value: 'San Marino',
//         hasChild,
//         key: '2.35',
//         children: [],
//       },
//       { title: 'Serbia', value: 'Serbia', hasChild, key: '2.36', children: [] },
//       {
//         title: 'Slovakia',
//         value: 'Slovakia',
//         hasChild,
//         key: '2.37',
//         children: [],
//       },
//       {
//         title: 'Slovenia',
//         value: 'Slovenia',
//         hasChild,
//         key: '2.38',
//         children: [],
//       },
//       { title: 'Spain', value: 'Spain', hasChild, key: '2.39', children: [] },
//       { title: 'Sweden', value: 'Sweden', hasChild, key: '2.40', children: [] },
//       {
//         title: 'Switzerland',
//         value: 'Switzerland',
//         hasChild,
//         key: '2.41',
//         children: [],
//       },
//       {
//         title: 'Ukraine',
//         value: 'Ukraine',
//         hasChild,
//         key: '2.42',
//         children: [],
//       },
//       {
//         title: 'United Kingdom',
//         value: 'United Kingdom',
//         hasChild,
//         key: '2.43',
//         children: [],
//       },
//     ],
//   },
// ];

// export const docIndustries: DocType[] = [
//   {
//     title: 'Industry 0',
//     value: 'Industry 0',
//     hasChild,
//     key: '1',
//     children: [],
//   },
//   {
//     title: 'Industry 1',
//     value: 'Industry 1',
//     hasChild,
//     key: '2',
//     children: [
//       {
//         title: 'Industry 1.0',
//         value: 'Industry 1.0',
//         hasChild,
//         key: '2.1',
//         children: [],
//       },
//       {
//         title: 'Industry 1.1',
//         value: 'Industry 1.1',
//         hasChild,
//         key: '2.2',
//         children: [],
//       },
//       {
//         title: 'Industry 1.2',
//         value: 'Industry 1.2',
//         hasChild,
//         key: '2.3',
//         children: [],
//       },
//     ],
//   },
//   {
//     title: 'Industry 2',
//     value: 'Industry 2',
//     hasChild,
//     key: '3',
//     children: [],
//   },
// ];
