export default function selectHighestRole(roles: string[]): string {
  if (roles.includes('admin')) {
    return 'admin';
  }
  if (roles.includes('admin_document_classification')) {
    return 'admin_document_classification';
  }
  if (roles.includes('admin_document_layout')) {
    return 'admin_document_layout';
  }
  return roles[0];
}
