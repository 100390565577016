import React, { FunctionComponent, ReactNode } from 'react';
import { Layout } from 'antd';
import Head from '../Header';

type Props = {
  children: ReactNode;
};

const LayoutWithHeader: FunctionComponent<Props> = ({ children }: Props) => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Head />
      {children}
    </Layout>
  );
};

export default LayoutWithHeader;
