import { gql } from 'apollo-boost';
import {
  DOCUMENTS_INFOS,
  DOCUMENTS_TYPES,
  CLASS_REGION_COUNTRY,
  CLASS_INDUSTRY,
} from './fragments';

export const getDocuments = gql`
  query getDocuments(
    $orderBy: [mapped_documents_order_by!]
    $filter: mapped_documents_bool_exp
    $offset: Int
    $limit: Int
  ) {
    mapped_documents(
      order_by: $orderBy
      limit: $limit
      offset: $offset
      where: $filter
    ) {
      ...documentInfos
    }
    mapped_documents_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
    classification_documents_types {
      ...documentsTypes
    }
  }
  ${DOCUMENTS_INFOS}
  ${DOCUMENTS_TYPES}
`;

export const getDocumentById = gql`
  query getDocumentsById($id: bigint!) {
    mapped_documents(where: { id: { _eq: $id } }) {
      ...documentInfos
    }
  }
  ${DOCUMENTS_INFOS}
`;

export const getDocumentsTypes = gql`
  query getDocumentsTypes {
    classification_documents_types {
      ...documentsTypes
    }
  }
  ${DOCUMENTS_TYPES}
`;

export const getIndustryClasses = gql`
  query getIndustryClasses {
    classification_industries {
      ...classIndustry
    }
  }
  ${CLASS_INDUSTRY}
`;

export const getRegionCountryClasses = gql`
  query getRegionCountryClasses {
    classification_regions_countries {
      ...classRegionCountry
    }
  }
  ${CLASS_REGION_COUNTRY}
`;

export const getLabelsClassification = gql`
  query getLabelsClassification {
    classification_documents_types {
      ...documentsTypes
    }
    classification_industries {
      ...classIndustry
    }
    classification_regions_countries {
      ...classRegionCountry
    }
  }
  ${DOCUMENTS_TYPES}
  ${CLASS_INDUSTRY}
  ${CLASS_REGION_COUNTRY}
`;

export const getNextNonLabelledDocumentForCompany = gql`
  query getNextNonLabelledDocumentForCompany($companyId: Int!) {
    mapped_documents(
      where: {
        mapped_company: { id: { _eq: $companyId } }
        _or: [
          { document_type: { _is_null: true } }
          { document_type: { _eq: "" } }
        ]
        _and: {
          _or: [
            { tags: { _nilike: "uninteresting" } }
            { tags: { _is_null: true } }
          ]
        }
      }
      limit: 1
    ) {
      id
    }
  }
`;

export const getNextUnLabelledDoc = gql`
  query getNextUnLabelledDoc($id: bigint!) {
    mapped_documents(
      where: {
        id: { _gt: $id }
        _or: [
          { document_type: { _is_null: true } }
          { document_type: { _eq: "" } }
        ]
        _and: {
          _or: [
            { tags: { _nilike: "uninteresting" } }
            { tags: { _is_null: true } }
          ]
        }
      }
      limit: 1
    ) {
      id
    }
  }
`;

export const getPreviousUnLabelledDoc = gql`
  query getPreviouUnLabelledDoc($id: bigint!) {
    mapped_documents(
      where: {
        id: { _lt: $id }
        _or: [
          { document_type: { _is_null: true } }
          { document_type: { _eq: "" } }
        ]
        _and: {
          _or: [
            { tags: { _nilike: "uninteresting" } }
            { tags: { _is_null: true } }
          ]
        }
      }
      limit: 1
    ) {
      id
    }
  }
`;

// document_date: {_is_null: true},
// document_ref_date: {_is_null: true},
// document_industry: {_is_null: true},
