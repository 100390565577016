import { SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Layout, Tag } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd/lib/table/Table';
import _ from 'lodash';
import React, {
  FunctionComponent,
  ReactNode,
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getTreeFromFlatData, TreeItem } from 'react-sortable-tree';
import { useAuth } from '../../auth';
import { updateDocument } from '../../graphql/mutations';
import { getDocuments } from '../../graphql/queries';
import { graphqlDocsTable, graphqlDocTypesTable } from '../../graphql/tables';
import {
  getParentKey,
  isMatchIgnoreEmpty,
  objectFormatter,
  parseTextToArray,
} from '../../scripts/generic';
import { Document, DocumentFromDB, DocumentTypeFromDB } from '../../types';
import { showError } from '../common/messages';
import Table, { ColumnType, RowRecord } from '../EdiTable';
import LayoutCustom from '../Layout';
import { columns, getDocTypeTreeSelect } from './constants';
import FilterDropown from './FilterDropdown';
import {
  FilterObject,
  SortObject,
  SortValue,
  useQuerystring,
} from './querystring';

const { Content } = Layout;

/**
 * Types definition
 */

type CellRenderFunctions = {
  // document_type: (types: number[]) => ReactNode;
  [key: string]: any;
};

// Operate on document values from the database for transformation to
// table-readable elements
const mapper = {
  tags: (tags: string) => parseTextToArray(tags),
  alt_companies: (alt_companies: string | null) =>
    parseTextToArray(alt_companies),
  document_type: (document_type: string | null) =>
    parseTextToArray(document_type).map((i) => parseInt(i, 10)),
  document_industry: (document_industry: string | null) =>
    parseTextToArray(document_industry),
  document_region: (document_region: string | null) =>
    parseTextToArray(document_region),
};

const mapFromDb = ({
  id,
  n_pages,
  tags,
  mapped_company,
  alt_companies,
  document_date,
  document_ref_date,
  document_type,
  document_industry,
  document_region,
  modified_date,
  found_at,
  label_by,
  url,
  url_text,
  url_type,
}: DocumentFromDB): Document => ({
  id,
  key: id,
  n_pages,
  document_date,
  document_ref_date,
  modified_date,
  found_at,
  url,
  url_text,
  url_type,
  label_by,
  companyName: mapped_company ? mapped_company.name : '',
  tags: mapper.tags(tags),
  alt_companies: mapper.alt_companies(alt_companies),
  document_type: mapper.document_type(document_type),
  document_industry: mapper.document_industry(document_industry),
  document_region: mapper.document_region(document_region),
});

const mapSortDirection: {
  asc: 'ascend';
  desc: 'descend';
} = {
  asc: 'ascend',
  desc: 'descend',
};

const getSortDirection = (
  col: string,
  sortObject: SortObject
): 'ascend' | 'descend' | undefined => {
  // the company name is mapped differently as it is a subfield
  // all the others
  if (
    col === 'companyName' &&
    sortObject.mapped_company &&
    sortObject.mapped_company.name
  ) {
    const sortValue = sortObject.mapped_company.name as SortValue;
    if (sortValue) {
      return mapSortDirection[sortValue];
    }
  }
  if (col in sortObject && sortObject[col as keyof SortObject]) {
    const sortValue = sortObject[col as keyof SortObject] as SortValue;
    if (sortValue) {
      return mapSortDirection[sortValue];
    }
  }
  return undefined;
};

const getFilterValue = (dataIndex: string, filterObject: FilterObject) => {
  // the company name is mapped differently as it is a subfield
  // all the others
  if (dataIndex === 'companyName' && filterObject.mapped_company) {
    // eslint-disable-next-line no-underscore-dangle
    return filterObject.mapped_company.name._ilike.split('%')[1];
  }
  if (dataIndex in filterObject) {
    // eslint-disable-next-line no-underscore-dangle
    return (filterObject[dataIndex as keyof FilterObject] as {
      _ilike: string;
    })._ilike.split('%')[1];
  }

  return '';
};

/**
 *
 * Elements
 */

const DocTypeSearch: FunctionComponent = ({ location: { search } }: any) => {
  // Auth to send user id whenever a document is updated
  const auth = useAuth();

  // Local state
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [itemsDisplay, setItemsDisplay] = useState(10);

  const [query, updateSorter, updateFilter, updateOffset] = useQuerystring(
    search
  );

  useEffect(() => {
    document.title = 'Datacie - Document search';
  }, []);

  // GraphQL
  // format string variables with '%' to match containing words
  const { loading, error, data } = useQuery(getDocuments, {
    variables: {
      orderBy: query.sort,
      filter: query.filter,
      offset: query.offset,
      limit: itemsDisplay,
    },
  });
  // Hook to update a document
  const [
    updateADocument,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(updateDocument);

  // Update the total count each time some data are loaded
  useEffect(() => {
    // console.log(data);
    if (data) {
      setItemsCount(data.mapped_documents_aggregate.aggregate.count);
    }
  }, [data]);

  // Show search or update error to the user
  useEffect(() => {
    if (error || errorUpdate) {
      showError({
        content:
          (error && error.message) || (errorUpdate && errorUpdate.message),
      });
    }
  }, [error, errorUpdate]);

  // Mapping of document type id to the corresponding name
  const typeIdToName = useMemo(() => {
    if (data) {
      return data[graphqlDocTypesTable].reduce(
        (
          acc: { [key: string]: string },
          { id, name }: { id: string; name: string }
        ) => {
          acc[id] = name;
          return acc;
        },
        {}
      );
    }
    return null;
  }, [data]);

  // Given the document types data, retrieve the tree to feed it to
  // the tree select component
  const docTypesTree = useMemo(() => {
    let treeData: TreeItem[] = [];
    if (data && data[graphqlDocTypesTable]) {
      const flatData = data[graphqlDocTypesTable]
        .map(({ id, name, path }: DocumentTypeFromDB) => ({
          id,
          title: name,
          label: name,
          value: id,
          path,
        }))
        .sort((a: DocumentTypeFromDB, b: DocumentTypeFromDB) => a.id - b.id);

      treeData = getTreeFromFlatData({
        flatData,
        getParentKey,
        rootKey: 0,
      });
    }

    return treeData;
  }, [data]);

  // console.log(query.sort);

  // Update the columns functions with the ones from above
  const columnsMapped = useMemo(() => {
    const inputElements: { [key: string]: undefined | ReactNode } = {
      document_type: getDocTypeTreeSelect(docTypesTree),
    };

    const renderFunctions: { [key: string]: undefined | ReactNode } = {
      document_type: (types: number[]) =>
        types.map((type) => (
          <Tag key={type}>{typeIdToName ? typeIdToName[type] : type}</Tag>
        )),
    };

    return columns.map((col: ColumnType) => {
      const sortOrder = getSortDirection(col.dataIndex, query.sort);

      // console.log(col.dataIndex, sortOrder);

      return {
        InputElement: inputElements[col.dataIndex],
        render: renderFunctions[col.dataIndex],
        sortOrder,
        ...col,
      };
    });
  }, [docTypesTree, typeIdToName, query.sort]);

  // Upon change of page, sorting or filtering, we need to update
  // state values to query more data
  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      newFilter: Record<string, ReactText[] | null>,
      newSorter:
        | SorterResult<Record<string, any>>
        | SorterResult<Record<string, any>>[]
    ) => {
      // let updatedSort = false;
      // let updatedOffset = false;
      if (newSorter) {
        const { field, order } = newSorter as { field: string; order: string };
        const currentValue = getSortDirection(field, query.sort);

        let sortValue: SortValue;
        if (order === 'ascend') {
          sortValue = 'asc';
        } else if (order === 'descend') {
          sortValue = 'desc';
        }

        if (currentValue !== sortValue) {
          updateSorter(`${field}Sort`, sortValue);
          // updatedSort = true;
        }
      }

      if (pagination.current && pagination.pageSize) {
        setItemsDisplay(pagination.pageSize);
        const newOffset = (pagination.current - 1) * pagination.pageSize;
        if (newOffset !== query.offset) {
          updateOffset(newOffset);
          // updatedOffset = true;
        }
      }
    },
    [query.sort, query.offset, updateSorter, updateOffset]
  );

  // Data from the backend formatted as rows
  const dataSource: Document[] | null = useMemo(() => {
    if (data) {
      return data[graphqlDocsTable].map(mapFromDb);
    }
    return null;
  }, [data]);

  // Interactions with rows
  const onRowClick = useCallback(
    (record: RowRecord) => (e: React.MouseEvent) => {
      if (e.ctrlKey) {
        const docURL = `/doc-type/flag/${record.id}`;
        window.open(docURL, '_blank');
        e.stopPropagation();
      } else if (e.shiftKey) {
        if (selectedRowKeys.includes(record.id)) {
          setSelectedRowKeys(selectedRowKeys.filter((x) => x !== record.id));
        } else {
          setSelectedRowKeys([...selectedRowKeys, record.id]);
        }
        e.preventDefault();
      }
    },
    [setSelectedRowKeys, selectedRowKeys]
  );

  const onRow = useCallback(
    (record: RowRecord) => {
      return {
        onClick: onRowClick(record), // click row
      };
    },
    [onRowClick]
  );

  const updateRow = useCallback(
    (record: RowRecord, updatingValues: Partial<Document>) => {
      const matchDoc = (doc: DocumentFromDB) =>
        selectedRowKeys.includes(doc.id) || doc.id === record.key;

      data.mapped_documents
        .filter(matchDoc)
        .forEach((currentDocument: DocumentFromDB) => {
          const formattedUpdate = objectFormatter({
            id: currentDocument.id,
            n_pages: currentDocument.n_pages,
            company: currentDocument.company,
            document_date: currentDocument.document_date,
            document_ref_date: currentDocument.document_ref_date,
            document_type: mapper.document_type(currentDocument.document_type),
            document_industry: mapper.document_industry(
              currentDocument.document_industry
            ),
            document_region: mapper.document_region(
              currentDocument.document_region
            ),
            tags: mapper.tags(currentDocument.tags),
            alt_companies: mapper.alt_companies(currentDocument.alt_companies),
            ...updatingValues,
          });

          if (
            !_.isMatchWith(currentDocument, formattedUpdate, isMatchIgnoreEmpty)
          ) {
            updateADocument({
              variables: {
                ...formattedUpdate,
                modified_date: new Date(),
                user_id: auth.user,
              },
              optimisticResponse: true,
            });
            setSelectedRowKeys([]);
          }
        });
    },
    [selectedRowKeys, setSelectedRowKeys, updateADocument, data, auth.user]
  );

  // Enable column search and update local filter state
  const getColumnSearchProps = useCallback(
    (dataIndex: any) => {
      const filterValue = getFilterValue(dataIndex, query.filter);

      return {
        filterDropdown: () => (
          <FilterDropown
            dataIndex={dataIndex}
            onValidate={updateFilter}
            defaultValue={filterValue}
          />
        ),
        filterIcon: () => (
          <SearchOutlined
            style={{
              color: filterValue !== '' ? '#1890ff' : undefined,
            }}
          />
        ),
      };
    },
    [query.filter, updateFilter]
  );

  return (
    <LayoutCustom>
      <Layout>
        <Content style={{ padding: '0 50px', overflow: 'auto' }}>
          <div>{`${itemsCount} elements`}</div>
          <Table
            updateRow={updateRow}
            columns={columnsMapped as ColumnType[]}
            dataSource={dataSource as RowRecord[]}
            loading={loading || loadingUpdate}
            onChange={onTableChange}
            onRow={onRow}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            getColumnSearchProps={getColumnSearchProps}
            pagination={{
              position: ['bottomLeft', 'topLeft'],
              current: query.offset / itemsDisplay + 1,
              total: itemsCount,
            }}
          />
        </Content>
      </Layout>
    </LayoutCustom>
  );
};

export default DocTypeSearch;
