import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, {
  Dispatch,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  ReactText,
  SetStateAction,
  useMemo,
} from 'react';
import EditableCell from './EditableCell';
import EditableRow from './EditableRow';

export type RowRecord = Record<string, any>;

export type ColumnType = {
  title: string;
  dataIndex: string;
  width?: number;
  render?: (value: any) => any;
  editable?: boolean;
  searchable?: boolean;
  sorter?: boolean;
  filters?: any;
  filterMultiple?: boolean;
  sortOrder?: 'ascend' | 'descend';
  InputElement?: ReactNode;
};

export type Props = {
  loading: boolean;
  selectedRowKeys: string[] | number[];
  getColumnSearchProps: any;
  pagination: any;
  columns: ColumnType[];
  setSelectedRowKeys: Dispatch<SetStateAction<number[]>>;
  dataSource: RowRecord[];
  onRow: (record: RowRecord, index?: number) => HTMLAttributes<HTMLElement>;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, ReactText[] | null>,
    sorter:
      | SorterResult<Record<string, any>>
      | SorterResult<Record<string, any>>[]
  ) => void;
  updateRow: (record: RowRecord, values: Record<string, string>) => void;
};

const TableCustom: FunctionComponent<Props> = ({
  selectedRowKeys,
  setSelectedRowKeys,
  getColumnSearchProps,
  columns,
  onChange,
  dataSource,
  loading,
  onRow,
  updateRow,
  pagination,
}: Props) => {
  const rowSelection = {
    selectedRowKeys,
    onChange: (_: ReactText[], selectedRows: RowRecord[]) => {
      setSelectedRowKeys(selectedRows.map((row) => row.key));
    },
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columnsMapped: ColumnType[] = useMemo(
    () =>
      columns.map((col: RowRecord) => {
        if (col.editable) {
          return {
            ...col,
            onCell: (record: RowRecord) => ({
              record,
              editable: col.editable,
              InputElement: col.InputElement,
              rules: col.rules,
              dataIndex: col.dataIndex,
              title: col.title,
              handleSave: updateRow,
            }),
          };
        }
        if (col.searchable) {
          return { ...col, ...getColumnSearchProps(col.dataIndex) };
        }

        return col;
      }),
    [columns, getColumnSearchProps, updateRow]
  );

  // console.log(columnsMapped);

  return (
    <Table
      style={{ userSelect: 'none' }}
      tableLayout="fixed"
      pagination={pagination}
      components={components}
      rowClassName={() => 'editable-row'}
      onChange={onChange}
      scroll={{ y: 650, x: 1300 }}
      rowSelection={rowSelection}
      columns={columnsMapped}
      dataSource={dataSource}
      loading={loading}
      onRow={onRow}
    />
  );
};

export default TableCustom;
