import { gql } from 'apollo-boost';
import { DOCUMENTS_INFOS } from './fragments';

export const updateDocument = gql`
  mutation updateDocument(
    $id: bigint!
    $n_pages: Int
    $tags: String
    $document_date: String
    $document_ref_date: String
    $document_type: String
    $document_industry: String
    $document_region: String
    $company: Int
    $alt_companies: String
    $modified_date: date
    $user_id: String!
  ) {
    update_mapped_documents(
      where: { id: { _eq: $id } }
      _set: {
        document_date: $document_date
        document_ref_date: $document_ref_date
        document_type: $document_type
        document_region: $document_region
        document_industry: $document_industry
        company: $company
        tags: $tags
        alt_companies: $alt_companies
        modified_date: $modified_date
        label_by: $user_id
      }
    ) {
      returning {
        ...documentInfos
      }
    }
  }
  ${DOCUMENTS_INFOS}
`;

export const updateTreeDocType = gql`
  mutation updateNode(
    $id: Int!
    $infos: String
    $path: ltree!
    $source: String
    $name: String!
    $user_id: String
  ) {
    update_classification_documents_types(
      where: { id: { _eq: $id } }
      _set: { infos: $infos, path: $path, source: $source, name: $name }
    ) {
      returning {
        id
        infos
        source
        name
        path
      }
    }
  }
`;

export const deleteNodeDocType = gql`
  mutation deleteNode($id: Int!) {
    delete_classification_documents_types(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const insertNodeDocType = gql`
  mutation insertNode(
    $id: Int!
    $infos: String
    $path: ltree!
    $source: String
    $name: String!
    $user_id: String
  ) {
    insert_classification_documents_types(
      objects: {
        id: $id
        infos: $infos
        path: $path
        source: $source
        name: $name
      }
    ) {
      returning {
        id
        infos
        source
        name
        path
      }
    }
  }
`;

export const updateTreeRegionCountry = gql`
  mutation updateNode(
    $id: Int!
    $infos: String
    $path: ltree!
    $type: String!
    $user_id: String
  ) {
    update_classification_regions_countries(
      where: { id: { _eq: $id } }
      _set: { infos: $infos, path: $path, type: $type }
    ) {
      returning {
        id
        infos
        type
        path
      }
    }
  }
`;
