import { message } from 'antd';
import { ReactNode } from 'react';

type MessageProps = {
  content?: string | ReactNode;
  duration?: number;
  onClose?: () => void;
  key?: string;
};

export const showSuccess = (props: MessageProps): void => {
  message.success(props);
};
export const showError = (props: MessageProps): void => {
  message.error(props);
};
export const showLoading = (props: MessageProps): void => {
  message.loading(props);
};
