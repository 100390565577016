import { CaretRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Layout,
  Popover,
  Select,
  TreeSelect,
  Typography,
} from 'antd';
import _ from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { useAuth } from '../../auth';
import { updateDocument } from '../../graphql/mutations';
import {
  getDocumentById,
  getLabelsClassification,
  getNextUnLabelledDoc,
} from '../../graphql/queries';
import {
  graphqlDocsTable,
  graphqlDocTypesTable,
  graphqlIndustriesTable,
  graphqlRegionsCountriesTable,
} from '../../graphql/tables';
import handleDateInput from '../../scripts/date_input';
import {
  normalizeString,
  objectFormatter,
  parseTextToArray,
} from '../../scripts/generic';
import { Document, DocumentFromDB, NodeFromDB } from '../../types';
import { showError } from '../common/messages';
import LayoutCustom from '../Layout';
import { docTags } from './constants';

const { REACT_APP_API_URL } = process.env;

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const { Text, Link } = Typography;

/**
 * Constants
 */

const getParentKey = (node: NodeFromDB) => {
  const lastPath = node.path.split('.').slice(-2);
  if (lastPath.length === 1) {
    return 0;
  }
  return parseInt(lastPath[0], 10);
};

const treeBaseProps = {
  multiple: true,
  treeCheckable: true,
  allowClear: true,
  showCheckedStrategy: SHOW_PARENT,
  filterTreeNode: (inputValue: any, treeNode: any) =>
    new RegExp(normalizeString(inputValue)).test(
      normalizeString(treeNode.label)
    ),
};

const dateRegexPattern = /(((0\d|[0-2]\d|3[0-1])-|^)(0\d|1[0-2]|H[1-2]|Q[1-4])-|^)(20[0-3]\d|19[8-9]\d|^)$/i;

/**
 * Types definition
 */

type DocumentTypeRaw = {
  id: number;
  path: string;
  name: string;
};

type DateField = 'document_ref_date' | 'document_date';

/**
 * Styling
 */

/**
 *
 * Elements
 */

const shortcutInfos = (
  <div>
    <p>
      <b>ctrl + U</b> Tag as uninteresting and move to the next document
    </p>
  </div>
);

const DocTypeFlagger: FunctionComponent<any> = (props: any) => {
  const history = useHistory();
  const auth = useAuth();

  const [form] = Form.useForm();

  const {
    match: {
      params: { docID },
    },
  } = props;

  const { data } = useQuery(getDocumentById, {
    variables: {
      id: docID,
    },
  });

  const documentsClasses = useQuery(getLabelsClassification);
  // const [
  //   loadNextNonLabelledDoc,
  //   { data: nextNonLabelledDocData },
  // ] = useLazyQuery(getNextNonLabelledDocumentForCompany, {
  //   fetchPolicy: "no-cache",
  // });

  const [
    loadNextUnLabelledDoc,
    { data: nextNonLabelledDocData },
  ] = useLazyQuery(getNextUnLabelledDoc, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    document.title = `Document type - ${docID}`;
  }, [docID]);

  useEffect(() => {
    if (nextNonLabelledDocData) {
      if (nextNonLabelledDocData[graphqlDocsTable].length > 0) {
        history.push(
          `/doc-type/flag/${nextNonLabelledDocData[graphqlDocsTable][0].id}`
        );
      } else {
        showError({ content: 'No more documents to label for this company!' });
      }
    }
  }, [history, nextNonLabelledDocData]);

  const documentData: DocumentFromDB = useMemo(
    () =>
      data && data[graphqlDocsTable].length > 0
        ? data[graphqlDocsTable][0]
        : {},
    [data]
  );

  const [
    updateADocument,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(updateDocument, { optimisticResponse: true });

  useEffect(() => {
    const key = 'loading';
    if (loadingUpdate) {
      // showLoading({content: "Updating...", key})
    } else if (errorUpdate) {
      showError({ content: errorUpdate.message, key });
    }
  }, [loadingUpdate, errorUpdate]);

  useEffect(() => {
    form.resetFields();
  }, [form, documentData.document_date, documentData.document_ref_date]);

  const docTypesTree = useMemo(() => {
    let treeData: any = [];
    if (documentsClasses.data && documentsClasses.data[graphqlDocTypesTable]) {
      const flatData = documentsClasses.data[graphqlDocTypesTable]
        .map(({ id, name, path }: DocumentTypeRaw) => ({
          id,
          title: name,
          label: name,
          value: id,
          path,
        }))
        .sort((a: DocumentTypeRaw, b: DocumentTypeRaw) => a.id - b.id);

      treeData = getTreeFromFlatData({
        flatData,
        getParentKey,
        rootKey: 0,
      });
    }

    return treeData;
  }, [documentsClasses.data]);

  const regionsCountriesTree = useMemo(() => {
    const table = graphqlRegionsCountriesTable;

    let treeData: any = [];
    if (documentsClasses.data && documentsClasses.data[table]) {
      const flatData = documentsClasses.data[table]
        .map(({ id, name, path }: DocumentTypeRaw) => ({
          id,
          title: name,
          label: name,
          value: id,
          path,
        }))
        .sort((a: DocumentTypeRaw, b: DocumentTypeRaw) => a.id - b.id);

      treeData = getTreeFromFlatData({
        flatData,
        getParentKey,
        rootKey: 0,
      });
    }

    return treeData;
  }, [documentsClasses.data]);

  const industriesTree = useMemo(() => {
    const table = graphqlIndustriesTable;

    let treeData: any = [];
    if (documentsClasses.data && documentsClasses.data[table]) {
      const flatData = documentsClasses.data[table]
        .map(({ id, name, path }: DocumentTypeRaw) => ({
          id,
          label: name,
          value: id,
          path,
        }))
        .sort((a: DocumentTypeRaw, b: DocumentTypeRaw) => a.id - b.id);

      treeData = getTreeFromFlatData({
        flatData,
        getParentKey,
        rootKey: 0,
      });
    }

    return treeData;
  }, [documentsClasses.data]);

  const save = useCallback(
    (updates: Partial<Document>) => {
      if (documentData.id) {
        const formattedUpdates = objectFormatter(updates);

        if (!_.isMatch(documentData, formattedUpdates)) {
          const updatedDocument = {
            id: documentData.id,
            company: documentData.company,
            document_date: documentData.document_date,
            document_ref_date: documentData.document_ref_date,
            n_pages: documentData.n_pages,
            document_type: documentData.document_type,
            document_region: documentData.document_region,
            document_industry: documentData.document_industry,
            tags: documentData.tags,
            ...formattedUpdates,
            modified_date: new Date(),
          };

          updateADocument({
            variables: { ...updatedDocument, user_id: auth.user },
          });
        }
      }
    },
    [auth.user, documentData, updateADocument]
  );

  const getNextDoc = useCallback(() => {
    loadNextUnLabelledDoc({
      variables: {
        id: docID,
      },
    });
  }, [loadNextUnLabelledDoc, docID]);

  // Key down handler: escape, space, left arrow, right arrow.
  const handleKeyDown = useCallback(
    (e) => {
      // console.log(e);
      if (e.ctrlKey && e.key === 's') {
        // saveDoc();
        showError({ content: 'Not implemented yet.', key: '6' });
        e.preventDefault();
      } else if (e.ctrlKey && e.key === 'u') {
        save({
          tags: Array.from(
            new Set([...parseTextToArray(documentData.tags), 'Uninteresting'])
          ),
        });
        setTimeout(getNextDoc, 300);
        e.preventDefault();
      } else if (e.key === 'ArrowRight') {
        getNextDoc();
      }
    },
    [save, getNextDoc, documentData]
  );

  // Add the keydown event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const updateDates = async () => {
    if (!form) {
      return;
    }

    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
      const fields = await form.getFieldsValue();

      Object.entries(fields).forEach(([key, value]) => {
        if (!value) {
          return;
        }

        const valueFormatted = handleDateInput(value as string);

        if (
          valueFormatted &&
          valueFormatted.match(dateRegexPattern) &&
          valueFormatted !== documentData[key as DateField] &&
          (valueFormatted || documentData[key as DateField])
        ) {
          const update: Partial<Document> = {};
          update[key as DateField] = valueFormatted;
          save(update);
        }
      });
    } catch (err) {}
  };

  return (
    <LayoutCustom>
      <Layout>
        <Layout.Sider width={400} className="site-layout-background">
          <div
            style={{
              background: '#fff',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: '0 10%',
              justifyContent: 'space-evenly',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Popover
                placement="bottom"
                title={<span>Shortcut infos</span>}
                content={shortcutInfos}
                trigger="click"
              >
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  icon={<InfoCircleOutlined />}
                />
              </Popover>
              <Button
                size="large"
                type="text"
                icon={<CaretRightOutlined />}
                title="Get the next (unlabelled) document given the current id."
                onClick={getNextDoc}
              />
            </div>
            <Link ellipsis href={documentData.url}>
              {documentData.url}
            </Link>
            <Text ellipsis title={documentData.url_text ?? undefined}>
              {documentData.url_text}
            </Text>
            <Select
              mode="multiple"
              value={parseTextToArray(documentData.tags)}
              allowClear
              onChange={(tags: string[]) => {
                save({ tags });
              }}
              placeholder="Tags"
            >
              {docTags.map((tag) => (
                <Option key={tag} value={tag}>
                  {tag}
                </Option>
              ))}
            </Select>
            <TreeSelect
              {...treeBaseProps}
              treeData={docTypesTree}
              value={
                documentData.document_type
                  ? parseTextToArray(documentData.document_type).map((i) =>
                      parseInt(i, 10)
                    )
                  : []
              }
              onChange={(document_type: number[]) => {
                save({ document_type });
              }}
              placeholder="Document type"
            />
            <TreeSelect
              {...treeBaseProps}
              treeData={regionsCountriesTree}
              value={
                documentData.document_region
                  ? parseTextToArray(documentData.document_region)
                  : []
              }
              onChange={(document_region: string[]) => {
                save({ document_region });
              }}
              placeholder="Document region/country"
            />
            <TreeSelect
              {...treeBaseProps}
              treeData={industriesTree}
              value={
                documentData.document_industry
                  ? parseTextToArray(documentData.document_industry)
                  : []
              }
              onChange={(document_industry: string[]) => {
                save({ document_industry });
              }}
              placeholder="Industry"
            />
            <Form
              form={form}
              onBlur={updateDates}
              initialValues={{
                ...documentData,
              }}
              onKeyDown={(e: React.KeyboardEvent) =>
                e.nativeEvent.stopImmediatePropagation()
              }
            >
              <Form.Item
                label="Publication date"
                name="document_date"
                rules={[
                  {
                    pattern: dateRegexPattern,
                    message: `Wrong formatting`,
                    required: false,
                  },
                ]}
              >
                <Input
                  key={documentData.document_date}
                  defaultValue={documentData.document_date ?? ''}
                />
              </Form.Item>
              <Form.Item
                label="Reference date"
                name="document_ref_date"
                rules={[
                  {
                    pattern: dateRegexPattern,
                    message: `Wrong formatting`,
                    required: false,
                  },
                ]}
              >
                <Input
                  key={documentData.document_ref_date}
                  defaultValue={documentData.document_ref_date ?? ''}
                />
              </Form.Item>
            </Form>
          </div>
        </Layout.Sider>

        <Layout.Content style={{ padding: '0 50px', display: 'flex' }}>
          <iframe
            title="Related File"
            key={documentData.id}
            src={
              `${REACT_APP_API_URL}/storage/o/${documentData.id}.pdf`
              // documentData.url
              //   ? documentData.url.replace("?download=1", "")
              //   : ""
            }
            width="100%"
            height="100%"
          />
        </Layout.Content>
      </Layout>
    </LayoutCustom>
  );
};

export default DocTypeFlagger;
