import { useQuery } from '@apollo/client';
import { Layout } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SortableTree, {
  getTreeFromFlatData,
  TreeItem,
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { getIndustryClasses } from '../../graphql/queries';
import { CenteredSpinner } from '../common';
import { showError } from '../common/messages';
import LayoutCustom from '../Layout/index';

/**
 * Constants
 */

const graphqlTable = 'classification_industries';

/**
 * Types definitions
 */

type NodeFromDB = {
  id: number;
  infos: string;
  type: string;
  path: string;
  name: string;
};

type Node = {
  title: string;
  subtitle: string;
  id: number;
  path: string;
};

const IndustryTree: FunctionComponent = () => {
  const { loading, error, data } = useQuery(getIndustryClasses);
  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  useEffect(() => {
    if (error) {
      showError({ content: error.message, duration: Infinity });
    }
  }, [error]);

  useEffect(() => {
    if (data && treeData.length === 0) {
      const flatData = data[graphqlTable]
        .map(({ id, type, name, path, infos }: NodeFromDB) => ({
          title: name,
          subtitle: `${type}${infos ? ` - ${infos}` : ''}`,
          id,
          path,
        }))
        .sort((a: Node, b: Node) => a.id - b.id);
      const getParentKey = (node: Node) => {
        const lastPath = node.path.split('.').slice(-2);
        if (lastPath.length === 1) {
          return 0;
        }
        return parseInt(lastPath[0], 10);
      };
      const rootKey = 0;

      const newTreeData = getTreeFromFlatData({
        flatData,
        getParentKey,
        rootKey,
      });
      setTreeData(newTreeData);
    }
  }, [data, treeData]);

  useEffect(() => {
    document.title = 'Tree - document types';
  });

  return (
    <LayoutCustom>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <Layout>
          <SortableTree
            treeData={treeData}
            onChange={(newTreeData: TreeItem[]) => {
              setTreeData(newTreeData);
            }}
            canDrag={false}
            style={{ maxWidth: '100%' }}
          />
        </Layout>
      )}
    </LayoutCustom>
  );
};

export default IndustryTree;
