import { FlatDataItem, TreeIndex } from 'react-sortable-tree';
import { FieldSplitable, fieldsSplitable, UntouchedField } from '../config';
import { Document, DocumentForGraphQL, Node, NodeFromDB } from '../types';

/**
 * Math.random should be unique because of its seeding algorithm.
 * Convert it to base 36 (numbers + letters), and grab the first 9 characters after the decimal.
 */
export const ID = (): string => {
  return `_${Math.random().toString(36).substr(2, 9)}`;
};

export const selfOrEmptyString = (s: string | undefined | null): string =>
  s || '';

export const normalizeString = (s: string): string =>
  s.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();

export const joinStringArrayForGraphql = (
  values: string[] | undefined
): undefined | string =>
  values && values.length ? `${values.join(',')}` : undefined;

export const stringArrayToGraphql = (
  values: string[] | string | number[]
): string => {
  if (values && values.length && typeof values === 'string') {
    return values;
  }
  if (values && values.length) {
    if (typeof values[0] === 'string') {
      return `${(values as string[]).join(',')}`;
    }
  }
  return `${(values as number[]).map((n) => `${n}`).join(',')}`;
};

export const parseTextToArray = (
  value: string | undefined | null
): string[] => {
  if (value && value.startsWith('"{') && value !== '{}' && value.length >= 2) {
    return value.slice(2, value.length - 2).split(',');
  }
  if (value && value.startsWith('{') && value !== '{}' && value.length >= 2) {
    return value.slice(1, value.length - 1).split(',');
  }
  if (value) {
    return value.split(',');
  }
  return [];
};

export const getParentKey = (node: Node): number => {
  const lastPath = node.path.split('.').slice(-2);
  if (lastPath.length === 1) {
    return 0;
  }
  return parseInt(lastPath[0], 10);
};

export const getTreeIndexFromNode = ({ treeIndex }: TreeIndex): number =>
  treeIndex;

export const formatFlatTree = (nodes: FlatDataItem[]): NodeFromDB[] => {
  const mappedNodes: { [id: string]: string } = {};

  return nodes.map(({ node: { id, subtitle, title, source }, parentNode }) => {
    let path: string;
    if (parentNode) {
      path = `${mappedNodes[parentNode.id]}.${id}`;
    } else {
      path = `${id}`;
    }
    mappedNodes[id] = path;
    return {
      path,
      id,
      name: title as string,
      infos: subtitle as string,
      source,
    };
  });
};

export const objectFormatter = (
  o: Partial<Document> & { company?: number | null }
): Partial<DocumentForGraphQL> => {
  return Object.entries(o).reduce((acc: any, [key, val]) => {
    if (fieldsSplitable.includes(key) && val) {
      acc[key as FieldSplitable] = stringArrayToGraphql(
        val as string[] | number[]
      );
    } else {
      acc[key as keyof UntouchedField] = val as string;
    }

    return acc;
  }, {});
};

export const isMatchIgnoreEmpty = (
  objValue: any,
  srcValue: any
): true | undefined => {
  if (!objValue && !srcValue) {
    return true;
  }
  return undefined;
};
