import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Popconfirm } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Node } from '../../types';

export type EditableField = {
  key: string;
  label: string;
  value: string;
  // InputElement?: FunctionComponent<{ autoFocus: boolean; onPressEnter: any }>;
  InputElement?: any;
};

export type Props = {
  onDelete: () => void;
  onOk: (newValues: Node) => void;
  onCancel: () => void;
  title?: string;
  values?: EditableField[];
};

const EditModal: FunctionComponent<Props> = ({
  title,
  values: originalValues,
  onOk,
  onCancel,
  onDelete,
}: Props) => {
  const [values, setValues] = useState<EditableField[]>();

  useEffect(() => {
    setValues(originalValues);
  }, [originalValues]);

  const [form] = Form.useForm();

  const visible = !!values;

  // Initial values that are passed to the form component
  const initialValues: { [key: string]: string } = useMemo(() => {
    if (originalValues) {
      return originalValues.reduce(
        (acc: any, { key, value }: EditableField) => {
          acc[key] = value;
          return acc;
        },
        {}
      );
    }
    return undefined;
  }, [originalValues]);

  // Whenever the initial values change, we reset the form
  useEffect(() => {
    if (!visible) return;
    form.resetFields();
  }, [form, visible]);

  const validateEdit = useCallback(() => {
    const newValues = form.getFieldsValue() as Node;

    Object.entries(newValues).forEach(([key, val]) => {
      if (val === undefined || val === null) {
        delete newValues[key as keyof Node];
      }
    });
    onOk(newValues);
  }, [form, onOk]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={validateEdit}
      title={title}
      footer={[
        <Button onClick={onCancel}>Cancel</Button>,
        <Popconfirm
          title="Are you sure？"
          onConfirm={onDelete}
          onCancel={onCancel}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button danger type="primary">
            Delete
          </Button>
        </Popconfirm>,
        <Button key="submit" type="primary" onClick={validateEdit}>
          Submit
        </Button>,
      ]}
    >
      <Form
        onFinish={validateEdit}
        form={form}
        initialValues={initialValues}
        layout="vertical"
      >
        {values &&
          values.map(({ key, label, InputElement }, i: number) => (
            <Form.Item key={label} name={key} label={label}>
              {InputElement ? (
                <InputElement autoFocus={i === 0} onPressEnter={validateEdit} />
              ) : (
                <Input autoFocus={i === 0} onPressEnter={validateEdit} />
              )}
            </Form.Item>
          ))}
      </Form>
    </Modal>
  );
};

export default EditModal;
