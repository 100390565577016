import { HomeOutlined } from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth';

const { Header } = Layout;
const { SubMenu } = Menu;

const Head: React.FunctionComponent = () => {
  const auth = useAuth();

  return (
    <Header className="header">
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[window.location.pathname]}
      >
        <Menu.Item key="/">
          <Link to="/">
            <HomeOutlined />
          </Link>
        </Menu.Item>
        {/* <SubMenu key="/doc-layout" title="Document layout">
          <Menu.Item key="/doc-layout/search">
            <Link to="/doc-layout/search">Search</Link>
          </Menu.Item>
        </SubMenu> */}
        <SubMenu key="/doc-type" title="Document type">
          <Menu.Item key="/doc-type/search">
            <Link to="/doc-type/search">Search</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="/trees" title="Trees">
          <Menu.Item key="/trees/documents-types">
            <Link to="/trees/documents-types">Document type</Link>
          </Menu.Item>
          <Menu.Item key="/trees/regions-countries">
            <Link to="/trees/regions-countries">Region/country</Link>
          </Menu.Item>
          <Menu.Item key="/trees/industries">
            <Link to="/trees/industries">Industry</Link>
          </Menu.Item>
        </SubMenu>
        {/* <Button type="primary" style={{position: "fixed", right: 72, top: 16}} onClick={() => auth.reloadToken(true)}>Reload</Button> */}
        <Button
          danger
          type="text"
          style={{ position: 'fixed', right: 16, top: 16 }}
          onClick={auth.logout}
        >
          Logout
        </Button>
      </Menu>
    </Header>
  );
};

export default Head;
