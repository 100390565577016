import { Search } from 'history';
import _ from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePrevious } from '../hooks';

export type SortValue = 'asc' | 'desc' | undefined;
export type SortObject = {
  mapped_company?: { name: SortValue };
  document_ref_date?: SortValue;
  document_date?: SortValue;
  tags?: SortValue;
  url_text?: SortValue;
  label_by?: SortValue;
};
export type FilterObject = {
  mapped_company?: { name: { _ilike: string } };
  document_ref_date?: { _ilike: string };
  document_date?: { _ilike: string };
  tags?: { _ilike: string };
  url_text?: { _ilike: string };
  url?: { _ilike: string };
  label_by?: { _ilike: string };
};
type SortParam =
  | 'companyName'
  | 'document_ref_date'
  | 'document_date'
  | 'tags'
  | 'url_text'
  | 'label_by';
type FilterParam =
  | 'companyName'
  | 'document_ref_date'
  | 'document_date'
  | 'tags'
  | 'url_text'
  | 'label_by';

type QueryParams = {
  filter: FilterObject;
  sort: SortObject;
  offset: number;
};

/**
 * Map sort query string to a GraphQL understandable sorting value.
 * Will treat any value that does not match "asc" | "desc" as null
 * @param value query string value
 */
export const mapSorter = (value: string | null): 'asc' | 'desc' | undefined => {
  if (value === 'asc') {
    return 'asc';
  }
  if (value === 'desc') {
    return 'desc';
  }
  return undefined;
};

const matchSorter = (key: string) =>
  [
    'companyNameSort',
    'document_ref_dateSort',
    'document_dateSort',
    'tagsSort',
    'url_textSort',
    'label_bySort',
  ].includes(key);

const matchFilter = (key: string) =>
  [
    'companyName',
    'document_ref_date',
    'document_date',
    'tags',
    'url_text',
    'url',
    'label_by',
  ].includes(key);

const nameToPath = {
  companyName: (name: string): FilterObject => ({
    mapped_company: { name: { _ilike: name } },
  }),
  document_ref_date: (document_ref_date: string): FilterObject => ({
    document_ref_date: { _ilike: document_ref_date },
  }),
  document_date: (document_date: string): FilterObject => ({
    document_date: { _ilike: document_date },
  }),
  tags: (tags: string): FilterObject => ({
    tags: { _ilike: tags },
  }),
  url_text: (url_text: string): FilterObject => ({
    url_text: { _ilike: url_text },
  }),
  url: (url: string): FilterObject => ({
    url: { _ilike: url },
  }),
  label_by: (label_by: string): FilterObject => ({
    label_by: { _ilike: label_by },
  }),
  companyNameSort: (name: SortValue): SortObject => ({
    mapped_company: { name },
  }),
  document_ref_dateSort: (document_ref_date: SortValue): SortObject => ({
    document_ref_date,
  }),
  document_dateSort: (document_date: SortValue): SortObject => ({
    document_date,
  }),
  tagsSort: (tags: SortValue): SortObject => ({
    tags,
  }),
  url_textSort: (url_text: SortValue): SortObject => ({
    url_text,
  }),
  label_bySort: (label_by: SortValue): SortObject => ({
    label_by,
  }),
};

// companyName: filters.name ? `%${filters.name}%` : undefined,
//       urlText: filters.url_text ? `%${filters.url_text}%` : undefined,
//       url: filters.url ? `%${filters.url}%` : undefined,
//       tags: filters.tags ? `%${filters.tags[0]}%` : undefined,

export const useQuerystring = (
  search: Search
): [QueryParams, any, any, any] => {
  const [queryParams, setQueryParams] = useState<QueryParams>({
    filter: {},
    sort: {},
    offset: 0,
  });
  const prevSearch = usePrevious(search);
  const history = useHistory();

  useEffect(() => {
    if (search === prevSearch) {
      return;
    }

    // console.log(search, prevSearch);

    const parsed = queryString.parse(search);

    // console.log(parsed);
    const offset = parseInt(parsed.offset as string, 10);

    const newParams: QueryParams = {
      filter: {},
      sort: {},
      // eslint-disable-next-line no-restricted-globals
      offset: isNaN(offset) ? 0 : offset,
    };
    Object.entries(parsed).forEach(([key, value]) => {
      if (value && matchSorter(key)) {
        newParams.sort = {
          ...newParams.sort,
          ...nameToPath[
            key as
              | 'companyNameSort'
              | 'document_ref_dateSort'
              | 'document_dateSort'
              | 'tagsSort'
              | 'url_textSort'
          ](value as SortValue),
        };
      } else if (matchFilter(key)) {
        newParams.filter = {
          ...newParams.filter,
          ...nameToPath[key as FilterParam](value as string),
        };
      }
    });

    if (!_.isEqual(queryParams, newParams)) {
      setQueryParams(newParams);
    }
  }, [search, prevSearch, queryParams]);

  const updateSorter = useCallback(
    (param: SortParam, value: SortValue) => {
      const query: {
        [key: string]: string | string[] | undefined | null;
      } = queryString.parse(search);

      // Allow only one sorter so remove others
      Object.entries(query).forEach(([key]) => {
        if (key.endsWith('Sort')) {
          delete query[key];
        }
      });

      query[param] = value as string;
      query.offset = '0';

      history.push({ search: queryString.stringify(query) });

      // console.log('pushed', queryString.stringify(query), 'via', history);
    },
    [history, search]
  );

  const updateFilter = useCallback(
    (param: FilterParam, value: string) => {
      const query = queryString.parse(search);

      if (value) {
        query[param] = `%${value}%`;
      } else if (query[param]) {
        delete query[param];
      }
      query.offset = '0';

      history.push({ search: queryString.stringify(query) });
    },
    [history, search]
  );

  const updateOffset = useCallback(
    (value: number) => {
      const query = queryString.parse(search);
      query.offset = `${value}`;
      history.push({ search: queryString.stringify(query) });
      setQueryParams({
        ...queryParams,
        offset: value,
      });
    },
    [queryParams, history, search, setQueryParams]
  );

  return [queryParams, updateSorter, updateFilter, updateOffset];
};
