import { Layout, Menu, Space, Table, Tag } from 'antd';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import LayoutCustom from '../Layout';

const { Content, Sider } = Layout;

const dataSource = [
  // {
  //   key: "1",
  //   appname: "Flag document layout",
  //   components: [
  //     { href: "/doc-layout/flag", label: "Flag" },
  //     { href: "/doc-layout/search", label: "Search" },
  //   ],
  // },
  {
    key: '2',
    appname: 'Flag document types',
    components: [{ href: '/doc-type/search', label: 'Search' }],
  },
  {
    key: '3',
    appname: 'Classification trees',
    components: [
      { href: '/trees/documents-types', label: 'Document types' },
      { href: '/trees/regions-countries', label: 'Regions/countries' },
      { href: '/trees/industries', label: 'Industry (GICS)' },
    ],
  },
];

const columns = [
  {
    title: 'App Name',
    dataIndex: 'appname',
    key: 'appname',
  },
  {
    title: 'Components',
    key: 'components',
    dataIndex: 'components',
    render: (components: { href: string; label: string }[]) => (
      <Space>
        {components.map(({ href, label }) => {
          return (
            <Tag key={href}>
              <Link to={href}>{label}</Link>
            </Tag>
          );
        })}
      </Space>
    ),
  },
];

const Landing: FunctionComponent = () => {
  // console.log('Landing');

  return (
    <LayoutCustom>
      <Layout>
        <Sider>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%' }}
          />
        </Sider>
        <Content style={{ padding: '0 24px', minHeight: 280 }}>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </Content>
      </Layout>
    </LayoutCustom>
  );
};

export default Landing;
