import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

export const a = 0;

export const CenteredSpinner = ({
  size,
  style,
}: Partial<SpinProps>): ReactElement => {
  return (
    <Spin
      size={size ?? 'large'}
      style={{ position: 'absolute', top: '50%', left: '50%', ...style }}
    />
  );
};
