import { Input, Modal, Select, Tag, TreeSelect } from 'antd';
import { DataNode } from 'antd/lib/tree';
import React, {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  ReactNode,
  RefObject,
} from 'react';
import { TreeItem } from 'react-sortable-tree';
import { normalizeString } from '../../scripts/generic';
import { docTags } from '../DocTypeFlagger/constants';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const dateRegexPattern = /(((0\d|[0-2]\d|3[0-1])-|^)(0\d|1[0-2]|H[1-2]|Q[1-4])-|^)(20[0-3]\d|19[8-9]\d|^)$/i;

export const renderURL = (url: string): ReactNode => (
  <a
    style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
    href={url.replace('?download=1', '')}
  >
    {decodeURIComponent(new URL(url).pathname).slice(-70)}
  </a>
);

/**
 * Use the dom to retrieve the next row element in the table and focus on it
 * Works specifically with antd tables.
 * @param e
 */
const nextRowIfAvailable = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'Tab') {
    const td = (e.currentTarget as HTMLDivElement).closest(
      'td'
    ) as HTMLTableDataCellElement;
    const tr = td.parentElement as HTMLTableRowElement;

    if (
      e.shiftKey &&
      tr.previousElementSibling &&
      !tr.previousElementSibling.classList.contains('ant-table-measure-row')
    ) {
      const childNo = Array.prototype.indexOf.call(tr.children, td);
      const nextContent = tr.previousElementSibling.children[
        childNo
      ].querySelector('*') as HTMLTableCellElement;
      nextContent.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      nextContent.click();
    } else if (!e.shiftKey && tr.nextElementSibling) {
      const childNo = Array.prototype.indexOf.call(tr.children, td);
      const nextContent = tr.nextElementSibling.children[childNo].querySelector(
        '*'
      ) as HTMLTableCellElement;
      nextContent.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      nextContent.click();
    } else if (!e.shiftKey) {
      const nextPage = document.querySelector(
        "li.ant-pagination-next[aria-disabled='false']"
      );
      if (nextPage) {
        (nextPage as HTMLDivElement).click();
      }
    }
  }
};

const InputElement = React.forwardRef(
  (
    {
      value,
      style,
      onChange,
      onBlur,
      onPressEnter,
    }: {
      value: string;
      style: CSSProperties;
      onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
      onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
      onPressEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
    },
    ref
  ) => (
    <Input
      ref={ref as RefObject<Input>}
      onKeyDown={nextRowIfAvailable}
      value={value}
      style={style}
      onChange={onChange}
      onBlur={onBlur}
      onPressEnter={onPressEnter}
    />
  )
);

export const columns = [
  {
    title: 'Company',
    dataIndex: 'companyName',
    sorter: true,
    width: 100,
    searchable: true,
  },
  {
    title: 'Text',
    dataIndex: 'url_text',
    width: 200,
    searchable: true,
    render: (text: string): string => text.slice(0, 70),
  },

  {
    title: 'Url',
    dataIndex: 'url',
    width: 200,
    searchable: true,
    render: renderURL,
  },
  {
    title: 'Found at',
    dataIndex: 'found_at',
    width: 200,
    render: renderURL,
  },
  {
    title: 'Number of pages',
    dataIndex: 'n_pages',
    sorter: true,
    width: 100,
  },
  {
    title: 'Type',
    dataIndex: 'document_type',
    width: 100,
    editable: true,
  },
  {
    title: 'Publication date',
    dataIndex: 'document_date',
    sorter: true,
    editable: true,
    width: 100,
    InputElement,
    rules: [
      {
        pattern: dateRegexPattern,
        message: `Wrong formatting`,
        required: false,
      },
    ],
  },
  {
    title: 'Document ref date',
    dataIndex: 'document_ref_date',
    sorter: true,
    editable: true,
    InputElement,
    width: 100,
    rules: [
      {
        pattern: dateRegexPattern,
        message: `Wrong formatting`,
        required: false,
      },
    ],
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    width: 100,
    render: (tags: string[]): ReactNode =>
      tags.map((tag) => <Tag key={tag}>{tag.toUpperCase()}</Tag>),
    editable: true,
    filters: docTags.map((tag) => ({ text: tag, value: tag })),
    InputElement: (props: any) => {
      return (
        <Select
          style={{ width: 200 }}
          mode="multiple"
          autoFocus
          defaultOpen
          allowClear
          onKeyPress={nextRowIfAvailable}
          {...props}
        >
          {docTags.map((tag) => (
            <Option key={tag} value={tag}>
              {tag}
            </Option>
          ))}
        </Select>
      );
    },
  },
  {
    title: 'Label by',
    dataIndex: 'label_by',
    sorter: true,
    width: 100,
    ellipsis: true,
    render: (s: string) => s,
    searchable: true,
  },
];

export const getDocTypeTreeSelect = (docTypes: TreeItem[]): ReactNode =>
  React.forwardRef((props: { onPressEnter: () => void }, ref) => (
    <Modal title="Select document type" visible onOk={props.onPressEnter}>
      <TreeSelect
        autoFocus
        allowClear
        open
        // treeCheckStrictly
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        filterTreeNode={(inputValue: string, treeNode: any) =>
          new RegExp(normalizeString(inputValue)).test(
            normalizeString(treeNode.label)
          )
        }
        style={{ width: '100%' }}
        ref={ref as RefObject<TreeSelect<HTMLDivElement>>}
        treeData={docTypes as DataNode[]}
        {...props}
      />
    </Modal>
  ));
