import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { useAuth } from './auth';
import DocTypeFlagger from './components/DocTypeFlagger';
import DocTypeSearch from './components/DocTypeSearch';
import DocTypeTree from './components/DocTypeTree';
import ErrorNotFound from './components/ErrorNotFound';
import IndustryTree from './components/IndustryTree';
import Landing from './components/Landing';
import Login from './components/Login';
import RegionCountryTree from './components/RegionCountryTree';
import history from './history';
import { CenteredSpinner } from './components/common';

function App(): JSX.Element {
  const auth = useAuth();

  return (
    <BrowserRouter>
      <Switch>
        {auth.loading ? (
          <div style={{ width: '100%', height: '100%' }}>
            <CenteredSpinner />
          </div>
        ) : (
          <>
            {!auth.token && history.location.pathname !== '/login' && (
              <Redirect
                exact
                from="*"
                to={{
                  pathname: '/login',
                  state: { referrer: history.location.pathname },
                }}
              />
            )}
            <Route exact path="/" component={Landing} />
            <Route path="/doc-type/flag/:docID" component={DocTypeFlagger} />
            <Route path="/doc-type/search" component={DocTypeSearch} />
            <Route path="/trees/documents-types" component={DocTypeTree} />
            <Route path="/trees/industries" component={IndustryTree} />
            <Route
              path="/trees/regions-countries"
              component={RegionCountryTree}
            />
            <Route path="/login" component={Login} />
            <Route path="*" component={ErrorNotFound} />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
