import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, {
  useCallback,
  useState,
  useEffect,
  FunctionComponent,
} from 'react';

type Props = {
  defaultValue: string;
  dataIndex: string | number;
  onValidate: (dataIndex: string | number, value: string) => void;
};

const FilterDropown: FunctionComponent<Props> = ({
  dataIndex,
  onValidate,
  defaultValue,
}: Props) => {
  const [value, setValue] = useState('');

  const validate = useCallback(() => {
    onValidate(dataIndex, value);
  }, [dataIndex, onValidate, value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        autoFocus
        placeholder={`Search ${dataIndex}`}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onPressEnter={validate}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={validate}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => onValidate(dataIndex, '')}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default FilterDropown;
