const normalizeYearOnly = (s: string) => {
  switch (s.length) {
    case 1:
      return `202${s}`;
    case 2:
      return `20${s}`;
    case 3:
      return `2${s}`;
    default:
      return s;
  }
};

const normalizeNonYear = (s: string) => {
  switch (s.length) {
    case 1:
      return `0${s}`;
    default:
      return s;
  }
};

const normalizeYearAndOneValue = (strings: string[]) =>
  `${normalizeNonYear(strings[0])}-${normalizeYearOnly(strings[1])}`;

const normalizeYearAndTwoValues = (strings: string[]) =>
  `${normalizeNonYear(strings[0])}-${normalizeNonYear(
    strings[1]
  )}-${normalizeYearOnly(strings[2])}`;

const handleDateInput = (dateInput: string): string => {
  if (dateInput.replace(/[\W_]/g, '').length === 0) {
    return '';
  }

  const dashSeparated = dateInput.toUpperCase().replace(/[\W_]/g, '-');

  const split = dashSeparated.split('-');

  switch (split.length) {
    case 1:
      return normalizeYearOnly(dashSeparated);

    case 2:
      return normalizeYearAndOneValue(split);

    case 3:
      return normalizeYearAndTwoValues(split);

    default:
      return '';
  }
};

export default handleDateInput;
