export type FieldSplitable =
  | 'tags'
  | 'document_type'
  | 'alt_companies'
  | 'document_region'
  | 'document_industry';

export const fieldsSplitable = [
  'tags',
  'document_type',
  'alt_companies',
  'document_region',
  'document_industry',
];

export type UntouchedField = 'id' | 'modified_date';
