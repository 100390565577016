import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { FunctionComponent } from 'react';

export const Context = React.createContext<FormInstance | null>(null);

export type Props = {
  index: number;
};

// We basically have a form for each row.
// Each cell can have access to this form via the context

const EditableRow: FunctionComponent<Props> = ({ index, ...props }: Props) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <Context.Provider value={form}>
        <tr {...props} />
      </Context.Provider>
    </Form>
  );
};

export default EditableRow;
