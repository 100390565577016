import * as React from 'react';
import {
  useEffect,
  useState,
  useCallback,
  ReactElement,
  ReactNode,
} from 'react';

/**
 * Constants
 */

const jwtTokenKey = 'jwt_token';
const jwtExpiryKey = 'jwt_expiry';
const rolesKey = 'roles';
const userIdKey = 'user_id';

const { REACT_APP_API_URL } = process.env;

/**
 * Types definitions
 */

type AuthenticatorObject = {
  username: string;
  password: string;
};

export type AuthContext = {
  login: (authObj: AuthenticatorObject) => void;
  logout: () => void;
  reloadToken: () => Promise<void>;
  register: () => void;
  loading: boolean;
  token?: string;
  user?: string;
  roles?: string[];
  error?: Error;
};

type Props = {
  children: ReactNode;
};

export const AuthContext = React.createContext<AuthContext>({
  login: () => {},
  logout: () => {},
  reloadToken: () => Promise.resolve(),
  register: () => {},
  loading: false,
  token: undefined,
  user: undefined,
  roles: undefined,
});

const AuthProvider = ({ children }: Props): ReactElement => {
  const [user, setUser] = useState<string>();
  const [roles, setRoles] = useState<string[]>();
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  // Test the local storage if some saved values exist
  const testLocalStorage = useCallback((): boolean => {
    const tokenExists = !!localStorage.getItem(jwtTokenKey);
    const tokenNotExpired =
      Date.now() < parseInt(localStorage.getItem(jwtExpiryKey) as string, 10);

    return tokenExists && tokenNotExpired;
  }, []);

  // Parse the response of a request and save the token locally
  const handleTokenResponse = useCallback(
    async (res: Response): Promise<void> => {
      const {
        jwt_token: jwtToken,
        jwt_expires_in: jwtExpiresIn,
      } = await res.json();

      const {
        'x-hasura-allowed-roles': allowedRoles,
        'x-hasura-user-id': userId,
      } = JSON.parse(atob(jwtToken.split('.')[1]))[
        'https://hasura.io/jwt/claims'
      ];

      setRoles(allowedRoles);
      setUser(userId);
      setToken(jwtToken);

      localStorage.setItem(jwtTokenKey, jwtToken);
      localStorage.setItem(rolesKey, allowedRoles);
      localStorage.setItem(jwtExpiryKey, Date.now() + jwtExpiresIn);
      localStorage.setItem(userIdKey, userId);
      setError(undefined);
    },
    [setRoles, setUser, setToken, setError]
  );

  const reloadToken = useCallback(
    async (force = false): Promise<void> => {
      if (!force && testLocalStorage()) {
        return;
      }

      const res = await fetch(`${REACT_APP_API_URL}/auth/token/refresh`, {
        credentials: 'include',
      });

      if (res.status === 200) {
        await handleTokenResponse(res);
      } else {
        setError(new Error(`${res.status}`));
      }
    },
    [handleTokenResponse, testLocalStorage]
  );

  // Check on mount if a jwt token is stored and not expired
  useEffect(() => {
    if (testLocalStorage()) {
      setToken(localStorage.getItem(jwtTokenKey) as string);
      setRoles(localStorage.getItem(rolesKey)?.split(','));
      setUser(localStorage.getItem(userIdKey) as string);
      setLoading(false);
    } else {
      reloadToken()
        .catch()
        .finally(() => setLoading(false));
    }

    window.addEventListener('storage', () => {
      setToken(localStorage.getItem(jwtTokenKey) as string);
    });
  }, [testLocalStorage, reloadToken]);

  // const updatePassword = (new_password: string, old_password: string) => {
  //   fetch(`/auth/change-password/`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ new_password, old_password }),
  //   })
  //     .then((res) => res.text())
  //     .then(console.log);
  // };

  const login = useCallback(
    async ({ username, password }: AuthenticatorObject) => {
      try {
        setLoading(true);
        const res = await fetch(`${REACT_APP_API_URL}/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: username, password }),
          credentials: 'include',
        });

        await handleTokenResponse(res);

        setLoading(false);

        // console.log({ token: jwt_token })
      } catch (err) {
        // console.log(err);
        setLoading(false);
      }
    },
    [handleTokenResponse]
  );

  const register = () => {}; // register the user

  const logout = useCallback(async () => {
    // console.log('logging out');
    setLoading(true);
    await fetch(`${REACT_APP_API_URL}/auth/token/revoke/`, {
      method: 'POST',
      credentials: 'include',
    });
    localStorage.clear();
    setToken(undefined);
    setRoles(undefined);
    setUser(undefined);
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        roles,
        user,
        token,
        loading,
        login,
        logout,
        register,
        reloadToken,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContext => React.useContext(AuthContext);

export { AuthProvider, useAuth };
