import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React, {
  ElementType,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { showError } from '../common/messages';
import { Context as EditableContext } from './EditableRow';

export type Props = React.HTMLAttributes<HTMLElement> & {
  editable: boolean;
  InputElement: ElementType;
  rules: Rule[];
  handleSave: (record: Record<string, string>, values: any) => void;
  dataIndex: string;
  inputType: 'number' | 'text';
  record: Record<string, string>;
  index: number;
  children: React.ReactNode;
};

const EditableCell: FunctionComponent<Props> = ({
  editable,
  InputElement,
  rules,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: Props) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLElement>(null);
  const form = useContext(EditableContext);

  // Re-focus whenever we trigger the edition state
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = useCallback(() => {
    if (form && editable) {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex] ? record[dataIndex] : undefined,
      });
    }
  }, [dataIndex, editing, form, record, editable]);

  const save = useCallback(async () => {
    if (form) {
      try {
        const values = await form.validateFields();

        toggleEdit();
        handleSave(record, values);
      } catch (errInfo) {
        showError({ content: `Save failed: ${errInfo.message}` });
      }
    }
  }, [form, handleSave, record, toggleEdit]);

  return (
    <td {...restProps} style={{ height: 1 }}>
      {editable && editing && (
        <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
          <InputElement ref={inputRef} onBlur={save} onPressEnter={save} />
        </Form.Item>
      )}
      {editable && (
        <div
          role="none"
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      )}
      {!editable && children}
    </td>
  );
};

export default EditableCell;
