import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function usePrevious(value: any): any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery(): undefined | URLSearchParams {
  return new URLSearchParams(useLocation().search);
}
