import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { fromPromise } from 'apollo-link';
import { GraphQLError } from 'graphql';
import React, { ReactNode, FunctionComponent } from 'react';
import { useAuth } from '../auth';
import selectHighestRole from '../auth/select_role';

type Props = {
  children: ReactNode;
};

const { REACT_APP_API_URL, REACT_APP_MASTER_API } = process.env;

const httpLink = createHttpLink({
  uri: `${REACT_APP_API_URL}/v1/graphql`,
});

const Provider: FunctionComponent<Props> = ({ children }: Props) => {
  const auth = useAuth();

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (auth && auth.token) {
      const highestRole = auth.roles
        ? selectHighestRole(auth.roles)
        : undefined;
      operation.setContext({
        headers: REACT_APP_MASTER_API
          ? {
              Authorization: `Bearer ${auth.token}`,
              'X-Hasura-Admin-Secret': process.env.REACT_APP_MASTER_API,
              'X-Hasura-Role': highestRole,
            }
          : {
              Authorization: `Bearer ${auth.token}`,
              'X-Hasura-Role': highestRole,
            },
      });
    }
    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, operation, forward }: any) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err: Error) => {
        // console.log(err);
        // switch (err.extensions?.code) {
        //   case "UNAUTHENTICATED":
        return fromPromise(
          auth.reloadToken().catch((error: GraphQLError) => {
            if (error.extensions?.code !== 'invalid-headers') {
              // console.log(error);
              auth.logout();
            }
          })
        )
          .filter((value) => Boolean(value))
          .flatMap((accessToken) => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${accessToken}`,
              },
            });

            // retry the request, returning the new observable
            return forward(operation);
          });
        // }
      });
    }
  });

  // const wsLink = useMemo(
  //   () =>
  //     new WebSocketLink({
  //       uri: REACT_APP_API_URL_WS as string,
  //       options: {
  //         reconnect: true,
  //         connectionParams: {
  //           authorization: `Bearer ${auth.token}`,
  //         },
  //       },
  //     }),
  //   [auth.token]
  // );

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       !!auth.token &&
  //       definition.kind === "OperationDefinition" &&
  //       definition.operation === "subscription"
  //     );
  //   },
  //   wsLink,
  //   httpLink
  // );

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Document: {
          keyFields: ['id'],
        },
      },
    }),
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default Provider;
