import { EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Input, Layout } from 'antd';
import _ from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData,
  removeNodeAtPath,
  TreeItem,
  TreeNode,
  TreePath,
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { useAuth } from '../../auth';
import {
  deleteNodeDocType,
  insertNodeDocType,
  updateTreeDocType,
} from '../../graphql/mutations';
import { getDocumentsTypes } from '../../graphql/queries';
import {
  formatFlatTree,
  getParentKey,
  getTreeIndexFromNode as getNodeKey,
} from '../../scripts/generic';
import { Node, NodeFromDB, NodeValues } from '../../types';
import { CenteredSpinner } from '../common';
import { showError, showLoading, showSuccess } from '../common/messages';
import LayoutCustom from '../Layout/index';
import EditModal, { EditableField } from '../Trees/EditModal';
import InsertModal from '../Trees/InsertModal';

/**
 * Constants
 */

const graphqlTable = 'classification_documents_types';

const editableKeyToProps = {
  title: { label: 'Node name' },
  subtitle: { label: 'Node infos', InputElement: Input.TextArea },
  source: { label: 'Source' },
};

const DocTypeTree: FunctionComponent = () => {
  const auth = useAuth();

  // Local state
  const [treeData, setTreeData] = useState<TreeItem[]>([]);
  const [editNode, setEditNode] = useState<TreeNode & TreePath>();
  const [addNode, setAddNode] = useState<number | undefined>();

  // GraphQL
  const { loading, error, data } = useQuery(getDocumentsTypes);
  const [
    updateTree,
    { loading: loadingUpdate, called: calledUpdate },
  ] = useMutation(updateTreeDocType);
  const [
    insertNodeGQL,
    { loading: loadingInsert, error: errorInsert },
  ] = useMutation(insertNodeDocType);
  const [
    deleteNodeGQL,
    { loading: loadingDelete, error: errorDelete },
  ] = useMutation(deleteNodeDocType);

  // Update page title
  useEffect(() => {
    document.title = 'Tree - document types';
  });

  // Show error on
  useEffect(() => {
    if (error) {
      showError({ content: error.message, duration: Infinity });
    }

    if (errorInsert) {
      showError({ content: errorInsert.message, duration: Infinity });
    }

    if (errorDelete) {
      showError({ content: errorDelete.message, duration: Infinity });
    }
  }, [error, errorInsert, errorDelete]);

  useEffect(() => {
    if (data && treeData.length === 0) {
      // Flatten raw data from the database
      const flatData = data[graphqlTable]
        .map(({ id, infos, source, name, path }: NodeFromDB) => ({
          title: name,
          subtitle: infos,
          id,
          path,
          source,
        }))
        .sort((a: Node, b: Node) => a.id - b.id);
      const rootKey = 0;

      const newTreeData = getTreeFromFlatData({
        flatData,
        getParentKey,
        rootKey,
      });
      setTreeData(newTreeData);
    }
  }, [data, treeData]);

  const addChild = useCallback(
    (id: number) => {
      setAddNode(id);
    },
    [setAddNode]
  );

  const generateNodeProps = useCallback(
    ({ node, path }: TreeNode & TreePath) => {
      const openEdit = () => {
        setEditNode({ node, path });
      };

      return {
        buttons: [
          <Button onClick={openEdit}>
            <EditOutlined />
          </Button>,
          <Button onClick={() => addChild(node.id)} type="primary">
            <PlusOutlined />
          </Button>,
        ],
      };
    },
    [addChild, setEditNode]
  );

  const closeEdit = useCallback(() => {
    setEditNode(undefined);
  }, [setEditNode]);

  const updateNode = useCallback(
    (values: Node) => {
      if (editNode && editNode.node && values) {
        let smthChanged = false;
        const newTreeData = changeNodeAtPath({
          treeData,
          path: editNode.path,
          getNodeKey,
          newNode: ({ node }: { node: Node }) => {
            if (!_.isMatch(values, node)) {
              smthChanged = true;
            }
            return { ...node, ...values };
          },
        });

        if (smthChanged) {
          setTreeData(newTreeData);
        }

        // Clear the edit node value
        setEditNode(undefined);
      }
    },
    [editNode, treeData]
  );

  const editValues = useMemo(() => {
    if (editNode && editNode.node) {
      return Object.entries(editNode.node).reduce((acc, [key, value]) => {
        if (key in editableKeyToProps) {
          acc.push({
            key,
            label: key,
            value,
            // ...editableKeyToProps[key],
          });
          // console.log(editNode.values[key]);
        }
        return acc;
      }, [] as EditableField[]);
    }
    return undefined;
  }, [editNode]);

  const deleteNode = useCallback(() => {
    if (editNode && editNode.path) {
      const newTreeData = removeNodeAtPath({
        treeData,
        path: editNode.path,
        getNodeKey,
      });

      setTreeData(newTreeData);
      setEditNode(undefined);
    }
  }, [editNode, treeData]);

  const validateInsert = useCallback(
    (values: NodeValues) => {
      let maxId = 0;
      getFlatDataFromTree({
        treeData,
        getNodeKey,
        ignoreCollapsed: false,
      }).forEach(({ node: { id } }) => {
        maxId = Math.max(id, maxId);
      });

      const { treeData: newTreeData } = addNodeUnderParent({
        treeData,
        newNode: { id: maxId + 1, ...values },
        getNodeKey: ({ node }) => node.id,
        expandParent: true,
        parentKey: addNode,
      });

      setAddNode(undefined);

      setTreeData(newTreeData);
    },
    [addNode, treeData]
  );

  const save = useCallback(() => {
    // Flatten the tree and format it for the database
    const treeFormattedForDB = formatFlatTree(
      getFlatDataFromTree({
        treeData,
        getNodeKey,
        ignoreCollapsed: false,
      })
    );

    // Keep trace of nodes from the formatted tree
    // it will help us to delete other nodes
    const validatedNodes = new Set<NodeFromDB>();
    treeFormattedForDB.forEach((node) => {
      const originalNode = data[graphqlTable].filter(
        ({ id }: NodeFromDB) => id === node.id
      )[0];

      validatedNodes.add(originalNode);

      if (!originalNode) {
        // Insert if it is a new node
        insertNodeGQL({ variables: { ...node, user_id: auth.user } });
      } else if (!_.isMatch(originalNode, node)) {
        updateTree({ variables: { ...node, user_id: auth.user } });
      }
    });

    data[graphqlTable]
      .filter((node: NodeFromDB) => !validatedNodes.has(node))
      .forEach((node: NodeFromDB) => {
        deleteNodeGQL({ variables: { id: node.id } });
      });
  }, [auth.user, data, deleteNodeGQL, insertNodeGQL, treeData, updateTree]);

  useEffect(() => {
    const key = 'loader';
    if (loadingUpdate || loadingInsert || loadingDelete) {
      showLoading({ content: 'Saving...', key });
    } else if (
      loadingUpdate === false &&
      loadingInsert === false &&
      loadingDelete === false &&
      calledUpdate
    ) {
      showSuccess({ content: 'Saved', key, duration: 0.8 });
    }
  }, [loadingUpdate, calledUpdate, loadingInsert, loadingDelete]);

  return (
    <LayoutCustom>
      <Layout>
        <Layout>
          <Layout.Sider width={200}>
            <div
              style={{
                display: 'flex',
                backgroundColor: 'white',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 20,
              }}
            >
              <Button icon={<SaveOutlined />} onClick={save}>
                Save
              </Button>
            </div>
          </Layout.Sider>
          {loading ? (
            <CenteredSpinner />
          ) : (
            <Layout.Content style={{ padding: '0 50px' }}>
              <SortableTree
                generateNodeProps={generateNodeProps}
                treeData={treeData}
                onChange={(newTreeData: TreeItem[]) => {
                  setTreeData(newTreeData);
                }}
              />
              <EditModal
                values={editValues}
                onCancel={closeEdit}
                onDelete={deleteNode}
                onOk={updateNode}
                title={
                  editValues && `Editing ${editNode && editNode.node.title}`
                }
              />
              <InsertModal
                visible={addNode !== undefined}
                onCancel={() => setAddNode(undefined)}
                onOk={validateInsert}
                title="Insert child node"
                values={[
                  {
                    key: 'title',
                    label: 'Type',
                    InputElement: Input,
                    rules: [
                      { required: true, message: 'Please input the title!' },
                    ],
                  },
                  {
                    key: 'subtitle',
                    label: 'Infos',
                    InputElement: Input.TextArea,
                  },
                  { key: 'source', label: 'Source', InputElement: Input },
                ]}
              />
            </Layout.Content>
          )}
        </Layout>
      </Layout>
    </LayoutCustom>
  );
};

export default DocTypeTree;
