import { Button, Checkbox, Form, Input, Layout, Row, Spin } from 'antd';
import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../auth';

type LoginValues = {
  username: string;
  password: string;
};

type LoginFormProps = {
  login: ({ username, password }: LoginValues) => void;
};

interface RouteProps {
  location: {
    state?: {
      referrer: string;
    };
  };
}

const LoginForm = ({ login }: LoginFormProps) => {
  const wrapperColStyle = { offset: 8, span: 16 };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={(values) => login(values as LoginValues)}
        //   onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={wrapperColStyle}
          name="remember"
          valuePropName="checked"
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={wrapperColStyle}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

const LoginPage: FunctionComponent<RouteProps> = ({
  location: { state },
}: RouteProps) => {
  const auth = useAuth();

  // console.log(auth);

  if (auth.token) {
    const redirect =
      state && state.referrer !== '/login' ? state.referrer : '/';

    return <Redirect to={redirect} />;
  }

  if (auth.loading) {
    return (
      <Layout
        style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginForm login={auth.login} />
    </Layout>
  );
};

export default LoginPage;
