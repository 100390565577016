import { gql } from 'apollo-boost';

export const DOCUMENTS_INFOS = gql`
  fragment documentInfos on mapped_documents {
    document_date
    document_ref_date
    found_at
    modified_date
    id
    n_pages
    company
    tags
    label_by
    url
    url_text
    url_type
    alt_companies
    document_type
    document_region
    document_industry
    mapped_company {
      name
      id
    }
  }
`;

export const DOCUMENTS_TYPES = gql`
  fragment documentsTypes on classification_documents_types {
    id
    name
    path
    type
    infos
    source
  }
`;

export const CLASS_REGION_COUNTRY = gql`
  fragment classRegionCountry on classification_regions_countries {
    id
    name
    path
    type
    infos
    source
  }
`;

export const CLASS_INDUSTRY = gql`
  fragment classIndustry on classification_industries {
    id
    name
    path
    type
    infos
    source
  }
`;
